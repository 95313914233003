import { useFormField } from '@kaliber/forms'
import { useTranslate } from '/machinery/I18n'
import { DayPicker } from 'react-day-picker'
import { Icon } from '/features/buildingBlocks/Icon'
import { isoCountries, isoCountriesEntries } from '/machinery/iso-countries-list'
import Flags from 'country-flag-icons/react/3x2'
import { FloatingFocusManager, autoUpdate, offset, size, useDismiss, useFloating, useInteractions, FloatingPortal } from '@floating-ui/react'
import { useListDropdown } from '/machinery/useListDropdown'

import iconCheck from '/images/icons/check.raw.svg'
import iconClose from '/images/icons/close.raw.svg'
import chevronIcon from '/images/icons/chevron.raw.svg'
import iconCalendar from '/images/icons/calendar.raw.svg'

import 'react-day-picker/dist/style.css'
import styles from './FormField.css'

export function FormFieldInput({ field, label, type = 'text', required = undefined, description = undefined, placeholder = undefined, inputProps = {}, layoutClassName = undefined, disabled = false }) {
  const { name, state: { value, error, showError, invalid }, eventHandlers } = useFormField(field)

  return (
    <FieldWithLabelAndError id={name} {...{ error, showError, label, description, required, layoutClassName, disabled }}>
      <div className={styles.componentInput}>
        <input
          id={name}
          value={value || ''}
          className={cx(styles.input, showError && styles.inputError)}
          aria-label={label}
          placeholder={placeholder || ''}
          {...{ type, required, disabled }}
          {...inputProps}
          {...eventHandlers}
        />
        { !invalid &&  <ValidationCheck layoutClassName={styles.validationCheckLayout} />}
      </div>
    </FieldWithLabelAndError>
  )
}

export function FormFieldCheckbox({ field, label, description = undefined, descriptionRaw = undefined, value: checkedValue = true, required = undefined, inputProps = {}, layoutClassName = undefined, disabled = false }) {
  const { __ } = useTranslate()
  const { name, state: { value, error, showError }, eventHandlers: { onChange: handleChange, ...otherEventHandlers } } = useFormField(field)

  return (
    <FieldWithError {...{ error, showError, layoutClassName }}>
      <label className={cx(styles.componentCheckbox, disabled && styles.disabled)}>
        <input
          id={name}
          checked={value === checkedValue}
          type='checkbox'
          className={styles.checkboxInput}
          aria-label={label}
          onChange={e => handleChange(e.target.checked ? checkedValue : !checkedValue)}
          {...{ value, name, required, disabled }}
          {...otherEventHandlers}
          {...inputProps}
        />
        <div className={cx(styles.checkboxLabel, description && styles.hasDescription)}>
          <div className={styles.checkboxLabelMain}>{label} {!required && <span className={styles.optional}>{__`form-optional`}</span>}</div>
          {description && <div className={styles.checkboxLabelDescription}>{description}</div>}
          {descriptionRaw && <div className={styles.checkboxLabelDescription} dangerouslySetInnerHTML={{ __html: descriptionRaw }} />}
          {showError && description && <ErrorMessage {...{ error }} />}
        </div>
        <span className={cx(styles.checkboxIndicator, Boolean(value) && styles.checkboxIndicatorChecked)}>
          {Boolean(value) && <Icon icon={iconCheck} />}
        </span>
      </label>
    </FieldWithError>
  )
}

export function FormFieldCheckboxGroup({ field, label, fieldsWrapper: Wrapper = undefined, description = undefined, options, required = undefined, layoutClassName = undefined, disabled = false }) {
  const { name, state: { value, error, showError }, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)

  const InputsWrapper = Wrapper ?? React.Fragment

  return (
    <FieldWithLabelAndError id={name} {...{ required, label, error, showError, layoutClassName }}>
      <div className={styles.componentCheckboxGroup}>
        <InputsWrapper>
          {options.map(({ id, label }) => {
            const itemName = `${name}__${id}`
            const checked = value.includes(id)

            return (
              <label key={itemName} htmlFor={itemName} className={cx(styles.checkbox, disabled && styles.disabled)}>
                <input
                  key={itemName}
                  id={itemName}
                  type="checkbox"
                  value={id}
                  className={styles.checkboxInput}
                  aria-label={label}
                  onChange={handleChange(id)}
                  {...{ checked }}
                  {...eventHandlers}
                />
                <div className={cx(styles.checkboxLabel, description && styles.hasDescription)}>
                  <div className={styles.checkboxLabelMain}>{label}</div>
                </div>
                <span className={cx(styles.checkboxIndicator, checked && styles.checkboxIndicatorChecked)}>
                  {checked && <Icon icon={iconCheck} />}
                </span>
              </label>
            )
          })}
        </InputsWrapper>
      </div>
    </FieldWithLabelAndError>
  )

  function handleChange(itemValue) {
    return e => {
      onChange(e.target.checked
        ? value.concat(itemValue)
        : value.filter(x => x !== itemValue)
      )
    }
  }
}

export function FormFieldUpload({ field, label, required = undefined, inputProps = {}, layoutClassName = undefined, disabled = false }) {
  const { __ } = useTranslate()
  const { name, state: { value, error, showError }, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)

  return (
    <FieldWithLabelAndError id={name} {...{ error, showError, label, required, layoutClassName, disabled }}>
      {value ? (
        <button
          type="button"
          onClick={_ => onChange(null)}
          className={styles.fileUploadRemove}
          {...{ disabled }}
        >
          <span>{value.name}</span>
          <div className={styles.uploadCloseIcon}>
            <Icon icon={iconClose} />
          </div>
        </button>
      ) : (
        <div className={cx(styles.fileUploadButtonContainer, disabled && styles.fileUploadButtonContainerDisabled)}>
          <input
            {...{ name, required }}
            className={styles.inputFile}
            aria-label={label}
            type="file"
            {...eventHandlers}
            onChange={e => {
              onChange(e.currentTarget.files[0])
              eventHandlers.onBlur(e)
            }}
            onBlur={undefined}
            {...{ disabled }}
            {...inputProps}
          />
          <div className={styles.uploadFieldButton}>
            <span className={styles.uploadIcon}>
              <span className={styles.uploadChevron}><Icon icon={chevronIcon} /></span>
            </span>
            <span className={styles.uploadTitle}>{__`form-button-upload`}</span>
          </div>
        </div>
      )}
    </FieldWithLabelAndError>
  )
}

export function FormFieldPhoneNumber({ field, label, required = false, layoutClassName = undefined, disabled = false }) {
  const { name, state: { value, error, showError, invalid }, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)
  const selectedIndex = isoCountriesEntries.findIndex(x => x.iso2 === value.phoneIsoCode)

  const {
    isOpen,
    setIsOpen,
    context,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    activeIndex,
  } = useListDropdown({ selectedIndex })

  const FlagIcon = Flags[value.phoneIsoCode] || null
  const placeholder = isoCountries[value.phoneIsoCode]?.exampleNumber?.national || ''
  const countries = isoCountriesEntries.sort(({ label: labelA }, { label: labelB }) =>
    (labelA !== labelB) ? (labelA < labelB) ? -1 : 1 : 0)

  return (
    <FieldWithLabelAndError id={name} {...{ error, showError, label, required, layoutClassName, disabled }}>
      <div className={cx(styles.componentPhoneNumber, showError && styles.inputError)} {...getReferenceProps()}>
        <button className={styles.dialCodeSelectButton} onClick={() => setIsOpen(!isOpen)} type="button">
          <span className={styles.flagIcon}>{FlagIcon && <FlagIcon />}</span>
          <span>+{ value.phoneDialCode }</span>
          <span className={cx(styles.dropdownChevron, isOpen && styles.dropdownChevronOpen)}><Icon icon={chevronIcon} /></span>
        </button>
        <hr className={styles.divider} />
        <div className={styles.phoneContainer}>
          <input
            id={name}
            value={value?.phoneNumber || ''}
            className={styles.phoneInput}
            type='phone'
            aria-label={label}
            onChange={handlePhoneNumberChange}
            {...{ required, disabled, placeholder }}
            {...eventHandlers}
          />
          { !invalid && <ValidationCheck layoutClassName={styles.validationCheckLayout} />}
        </div>
        { isOpen && (
          <FloatingPortal>
            <FloatingFocusManager {...{ context }}>
              <ul className={styles.dialCodeDropdown} {...getFloatingProps()}>
                {countries.map(({ lang, label, dialCode, iso2 }, i) => {
                  const Icon = Flags[iso2] || null

                  return (
                    <li key={i} className={styles.dialCodeOption}>
                      <button
                        className={styles.dialCodeOptionButton}
                        data-active={i === activeIndex}
                        {...getItemProps(i, label, {
                          onClick: () => handleLanguageChange({ iso2, dialCode })
                        })}
                      >
                        <span className={styles.dialCodeName}>
                          <span className={styles.dialCodeIcon}>{ Icon && <Icon /> }</span>
                          <span>{label}</span>
                        </span>
                        <span>+{ dialCode }</span>
                      </button>
                    </li>
                  )
                })}
              </ul>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </div>
    </FieldWithLabelAndError>
  )

  function handleLanguageChange({ iso2: phoneIsoCode, dialCode: phoneDialCode }) {
    onChange({ ...value, phoneIsoCode, phoneDialCode })
    setIsOpen(false)
  }

  function handlePhoneNumberChange(e) {
    onChange({ ...value, phoneNumber: e.currentTarget.value })
  }
}

export function FormFieldSelect({
  field,
  label,
  options,
  required = undefined,
  description = undefined,
  placeholder = undefined,
  inputProps = {},
  layoutClassName = undefined
}) {
  const { name, state: { value, error, showError }, eventHandlers: { onChange } } = useFormField(field)
  const selectedIndex = options.findIndex(x => x.value === value)
  const valueLabel = options.find(x => x.value === value)?.label
  const {
    isOpen,
    setIsOpen,
    context,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    activeIndex,
  } = useListDropdown({ selectedIndex })

  return (
    <FieldWithLabelAndError id={name} {...{ error, showError, label, description, required, layoutClassName }}>
      <button className={cx(styles.select, !valueLabel && styles.selectNoValue)} {...getReferenceProps({ onClick: () => setIsOpen(!isOpen) })} type='button'>
        <span>{ valueLabel ?? placeholder }</span>
        <span className={cx(styles.selectChevron, isOpen && styles.selectChevronOpen)}><Icon icon={chevronIcon} /></span>
      </button>
      { isOpen && (
        <FloatingPortal>
          <FloatingFocusManager {...{ context }}>
            <ul className={styles.selectDropdown} {...getFloatingProps()}>
              {options.map(({ label, value }, i) => (
                <li
                  key={i}
                  className={cx(styles.selectOption)}
                >
                  <button
                    {...getItemProps(i, label, { onClick: () => {
                      onChange(value)
                      setIsOpen(false)
                    } })}
                    className={cx(styles.selectOptionButton)}
                    data-active={i === activeIndex}
                  >
                    {label}
                  </button>
                </li>
              ))}
            </ul>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </FieldWithLabelAndError>
  )
}

export function FormFieldDate({ language, label, description, required = undefined, field, disabled = false, layoutClassName = undefined }) {
  const { name, state: { value, error, showError }, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)
  const { isOpen, setIsOpen, getReferenceProps, getFloatingProps, context } = useDateDropdown()


  return (
    <FieldWithLabelAndError id={name} {...{ error, showError, label, description, required, layoutClassName, disabled }}>
      <div className={cx(styles.dateField, showError && styles.inputError)} {...getReferenceProps()}>
        <input
          className={styles.dateInput}
          id={name}
          type="date"
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          lang={language || ''}
          {...eventHandlers}
          {...{ disabled }}
        />
        <button className={styles.dateDropdownButton} onClick={() => setIsOpen(!isOpen)}><Icon icon={iconCalendar} /></button>
      </div>
      {isOpen && <FloatingPortal>
        <FloatingFocusManager {...{ context }}>
          <div className={styles.dateDropdown} {...getFloatingProps()}>
            <DayPicker
              classNames={{
                button: styles.daypickerButton,
                caption_label: styles.daypickerLabel,
                nav: styles.daypickerNav,
                nav_button_next: styles.daypickerNavButton,
                nav_button_previous: styles.daypickerNavButton,
                table: styles.daypickerTable,
                head: styles.daypickerTableHead
              }}
              modifiersClassNames={{
                hover: styles.daypickerSelected,
                selected: styles.daypickerSelected
              }}
              mode="single"
              selected={value}
              onSelect={(date) => {
                onChange(new Date(date))
              }}
              {...{ disabled }}
            />
          </div>
        </FloatingFocusManager>
      </FloatingPortal>}
    </FieldWithLabelAndError>
  )
}

function FieldWithLabelAndError({ children, error, id, label, description = undefined, required, showError, disabled = false, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <FieldWithError {...{ error, showError, layoutClassName }}>
      <div className={cx(styles.componentFieldWithLabelAndError, disabled && styles.disabled)}>
        <label
          className={styles.label}
          htmlFor={id}
          data-label={label}
        >
          <div className={styles.labelMain}>{label} {!required && <span className={styles.optional}>{__`form-optional`}</span>}</div>
          {description && <div className={styles.labelDescription}>{description}</div>}
        </label>
        {children}
      </div>
    </FieldWithError>
  )
}

function FieldWithError({ children, error, showError, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentFieldWithError, layoutClassName)}>
      <div>{ children }</div>
      {showError && <ErrorMessage {...{ error }} layoutClassName={styles.errorMessageLayout} />}
    </div>
  )
}

function ErrorMessage({ error, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  const validationErrors = {
    required: _ => __`form-validation-required`,
    requiredWithFieldName: fieldName => __({ fieldName: fieldName.toLowerCase() })`form-validation-required-fieldname`,
    checkRequired: _ => __`form-validation-required`,
    requiredTermsAndConditions: _ => __`form-validation-terms-and-conditions-required`,
    cvRequired: _ => __`form-validation-cv-required`,
    fileEmpty: _ => __`form-validation-file-empty`,
    maxFileSize: x => __({ fileSize: bytesToMb(x) })`form-validation-file-max-size`,
    fileExtension: extensions => {
      return __({ fileExtensions: readable(extensions) })`form-validation-file-extension`

      function readable(array) {
        const [last, ...rest] = array.slice().reverse()
        return `${rest.reverse().join(', ')} ${__`form-validation-file-extension-or`} ${last}`
      }
    },
    phoneInvalid: _ => __`form-validation-phone`,
    email: _ => __`form-validation-email`,
    number: _ => __`form-validation-number`,
  }

  return <div className={cx(styles.componentErrorMessage, layoutClassName)}>{validationErrors[error.id](...error.params)}</div>

  function bytesToMb(bytes) {
    return Math.round(bytes / (1024 * 1024))
  }
}

function ValidationCheck({ layoutClassName = undefined }) {
  return (
    <span className={cx(styles.componentValidationCheck, layoutClassName)}>
      <Icon icon={iconCheck} layoutClassName={styles.checkLayout} />
    </span>
  )
}

function useDateDropdown() {
  const [isOpen, setIsOpen] = React.useState(false)
  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(1),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${elements.reference.getBoundingClientRect().width}px`,
            maxHeight: `${availableHeight - 32}px`
          })
        },
      }),
    ]
  })

  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  return {
    isOpen,
    setIsOpen,
    context,
    getReferenceProps: (args = {}) => getReferenceProps({
      ...args,
      ref: refs.setReference
    }),
    getFloatingProps: (args = {}) => getFloatingProps({
      ...args,
      style: floatingStyles
    })
  }
}
