import { determineLinkProps } from '/machinery/link'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Link.css'

import chevronRight from '/images/icons/chevron-right.raw.svg'

export function Link({ href, dataX, children, targetSelf = undefined, layoutClassName = undefined }) {
  return (
    <LinkBase className={layoutClassName} {...{ href, dataX, children, targetSelf }}>
      {children}
    </LinkBase>
  )
}

export function LinkWithLabel({ href, dataX, children, targetSelf = undefined, layoutClassName = undefined }) {
  return (
    <LinkBase className={cx(styles.componentWithLabel, layoutClassName)} {...{ href, dataX, children, targetSelf }}>
      <span className={styles.iconContainer}>
        <Icon icon={chevronRight} />
      </span>
      <span>{children}</span>
    </LinkBase>
  )
}

function LinkBase({ href, dataX, children, targetSelf = undefined, className }) {
  const { rel, target } = determineLinkProps({ href, targetSelf })

  return (
    <a data-x={dataX} className={cx(styles.componentBase, className)} {...{ href, rel, target }}>
      {children}
    </a>
  )
}
